<content-loading-animation *ngIf="loading"></content-loading-animation>

<div class="details-panel" *ngIf="!loading">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="1x" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="1x" class="d-lg-none"></fa-icon></button
                >{{ role.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="1x"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button (click)="edit(role)" class="text-primary" title="{{ 'EDIT' | translate }}" mat-menu-item>
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button (click)="clone(role)" class="text-primary" title="{{ 'CLONE' | translate }}" mat-menu-item>
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button (click)="deleteRole()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item>
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <div class="btn-group mb-2" role="group">
                    <button type="button" class="btn btn-outline-primary" (click)="edit(role)" title="{{ 'EDIT' | translate }}">
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary" (click)="clone(role)" title="{{ 'CLONE' | translate }}">
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="deleteRole()" title="{{ 'DELETE' | translate }}">
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="details-form">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>NAME</h5>
                                            <div class="col-12">
                                                <span>{{ role.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>TAG</h5>
                                            <div class="col-12">
                                                <span>{{ role.resourceTag.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>USERS</h5>
                                            <div class="col-12">
                                                <span>{{ role.users && role.users.length > 0 ? (role.users | brackets) : "-" }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>GROUPS</h5>
                                            <div class="col-12">
                                                <span>{{ role.groups && role.groups.length > 0 ? (role.groups | names) : "-" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h5 class="col-12" translate>PERMISSIONS</h5>
                                        <div class="table-responsive">
                                            <app-role-permissions-table [role]="role"></app-role-permissions-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
