<table class="table table-sm bordered mb-0">
    <thead>
        <tr>
            <th scope="col" class="w-100" translate>OBJECT</th>
            <th scope="col" class="w-50px text-center" translate>READ</th>
            <th scope="col" class="w-50px text-center" translate>WRITE</th>
            <th scope="col" class="w-50px text-center" translate>NOTIFY</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let section of tableData">
            <tr class="thead-light">
                <td>{{ section.type | uppercase | translate }}</td>
                <td class="text-center">
                    <fa-icon icon="eye" size="1x" [ngClass]="{ 'very-disabled': !section.read, disabled: section.read === 1 }"></fa-icon>
                </td>
                <td class="text-center">
                    <fa-icon icon="pencil" size="1x" [ngClass]="{ 'very-disabled': !section.write, disabled: section.write === 1 }"></fa-icon>
                </td>
                <td class="text-center">
                    <fa-icon icon="envelope" size="1x" [ngClass]="{ 'very-disabled': !section.notifications, disabled: section.notifications === 1 }"></fa-icon>
                </td>
            </tr>
            <ng-container *ngIf="section.objects.length">
                <tr>
                    <td colspan="4" class="p-0">
                        <table class="table table-sm table-nested">
                            <tbody>
                                <tr *ngFor="let object of section.objects">
                                    <td class="w-100">{{ object.object_type | uppercase | translate }}</td>
                                    <td class="w-50px text-center">
                                        <fa-icon icon="eye" size="1x" [ngClass]="{ 'very-disabled': !object.read }"></fa-icon>
                                    </td>
                                    <td class="w-50px text-center">
                                        <fa-icon icon="pencil" size="1x" [ngClass]="{ 'very-disabled': !object.write }"></fa-icon>
                                    </td>
                                    <td class="w-50px text-center">
                                        <fa-icon icon="envelope" size="1x" [ngClass]="{ 'very-disabled': !object.notifications }"></fa-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>
